import { useState } from 'react';
import type React from 'react';

import { IonAvatar, IonImg } from '@ionic/react';

import './TSAvatar.scss';

import { isBurns } from '../../../helpers/device.helper';
import { RaygunErrorHandlerService } from '../../../services/raygun';

const { logError } = RaygunErrorHandlerService();

interface TSAvatarProps {
  imgUrl: string | null | undefined;
  name?: string | null;
  className?: string;
  height?: number;
  width?: number;
  grayscaled?: boolean;
}

/** Important to pass the image URL as TSAvatar component key to have it recreated if the image/URL changed */
export const TSAvatar: React.FC<TSAvatarProps> = ({
  imgUrl,
  name,
  className,
  height = 70,
  width = height,
  grayscaled,
}) => {
  const [loadingImgFailed, setLoadingImgFailed] = useState(false);

  return (
    <IonAvatar className={`advocate-avatar ${className} ${isBurns ? 'burns' : ''}`} style={{ height, width }}>
      {imgUrl && !loadingImgFailed ? (
        <IonImg
          className={`${grayscaled ? 'unavailable-grayscaled' : ''}`}
          alt="Advocate's profile picture"
          src={imgUrl}
          onIonError={() => {
            setLoadingImgFailed(true);
            logError(new Error('loading avatar image failed'), ['TSAvatar', 'onIonError'], { imgUrl });
          }}
        />
      ) : (
        <p className="title-large bold-text ion-text-uppercase">{name && name.charAt(0)}</p>
      )}
    </IonAvatar>
  );
};
