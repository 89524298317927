import type { AxiosError } from 'axios';
import rg4js from 'raygun4js';

import { Device } from '@capacitor/device';

import { environment } from '../environment/environment';
import { getAppCode, getOrgCode } from '../helpers/device.helper';
import type { TokenValue } from '../pages/advocate/AdvocateAuthSlice';

const isLocal = environment.environment_deploy === 'local';

rg4js('options', {
  disableErrorTracking: isLocal,
});
rg4js('apiKey', environment.rayGunKey);
rg4js('setVersion', `${environment.versionNumber}.${environment.buildNumber}`);
rg4js('enableCrashReporting', !isLocal);
rg4js('withTags', [environment.environment_deploy, getAppCode(), getOrgCode(), `v${environment.versionNumber}`]);
rg4js('filterSensitiveData', ['email', 'pass', 'retype_pass', 'new_pass', 'old_pass']);

export const RaygunErrorHandlerService = () => {
  const logError = (err: unknown, tags: string[], customData?: unknown) => {
    const error = err as AxiosError;
    if (error.response?.status) {
      tags = [error.response.status.toString(), ...tags];
    }

    const data = { error, tags, customData };

    if (!isLocal && navigator.onLine) {
      rg4js('send', data);
    }

    if (isLocal && !(error.response && error.response.status === 401)) {
      // eslint-disable-next-line no-console
      console.error(data);
    }
  };

  const setRaygunUser = async (user?: TokenValue) => {
    const { identifier: device_id } = await Device.getId();
    let identifier = `device_id: ${device_id}, app_code: ${getAppCode()}, org_code: ${getOrgCode()}`;

    if (user) {
      identifier += `, role: advocate, advocate_id: ${user.user_id}`;
    } else {
      identifier += ', role: guest';
    }

    rg4js('setUser', { identifier });
  };

  return { logError, setRaygunUser };
};
