import { useCallback, useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

import { Capacitor, type PluginListenerHandle } from '@capacitor/core';

import {
  APP_MODAL,
  APP_STATUS,
  checkUpgrade,
  fetchGuestInfo,
  setAppModal,
} from '../components/updateApp/appStatusSlice';
import { environment } from '../environment/environment';
import { isBurns, isNative, isTS, setOrgBodyClass } from '../helpers/device.helper';
import { initNativeNotification, initWebNotification } from '../helpers/notification.helper';
import {
  checkHasNewFirstAlertsCommunity,
  getAlertAcceptedDisclaimer,
  getAlertPromptResponse,
  getDartAlertInfo,
} from '../pages/guest/firstAlertsSlice';
import { endGuestLeftChat } from '../pages/guest/guestChatSlice';
import { getPreferences } from '../pages/guest/guestSlice';
import { useAppDispatch, useAppSelector } from '../store';

export const useInitApp = () => {
  const dispatch = useAppDispatch();
  const posthog = usePostHog();
  const appOrgCode = useAppSelector((state) => state.appStatusSlice.appOrgCode);
  const guestId = useAppSelector((state) => state.appStatusSlice.guestInfo?.guest_id);
  const currentUser = useAppSelector((state) => state.advocateAuthSlice.currentUser);
  const appUpdateDismissed = useAppSelector((state) => state.appStatusSlice.appUpdateDismissed);
  const isAppReady = useAppSelector((state) => state.appStatusSlice.isAppReady);
  const didInitialAreaChange = useAppSelector((state) => state.appStatusSlice.didInitialAreaChange);

  const handleAppUpgrade = useCallback(async () => {
    const checkUpgradeResponse = await dispatch(checkUpgrade());
    if (
      !appUpdateDismissed &&
      checkUpgrade.fulfilled.match(checkUpgradeResponse) &&
      [APP_STATUS.APP_EXPIRED, APP_STATUS.APP_UPDATE_AVAILABLE].includes(checkUpgradeResponse.payload)
    ) {
      dispatch(setAppModal(APP_MODAL.UPDATE));
    }
  }, [dispatch, appUpdateDismissed]);

  const initWeb = useCallback(() => {
    initWebNotification();
    dispatch(getPreferences());
    dispatch(endGuestLeftChat());
  }, [dispatch]);

  const initNative = useCallback(() => {
    initNativeNotification();
    dispatch(fetchGuestInfo());
    dispatch(getPreferences());
    dispatch(endGuestLeftChat());
    dispatch(getAlertPromptResponse());
    dispatch(checkHasNewFirstAlertsCommunity());
    dispatch(getAlertAcceptedDisclaimer());
    dispatch(getDartAlertInfo());
  }, [dispatch]);

  useEffect(() => {
    document.getElementById('loader')?.remove();
    setOrgBodyClass();

    if (isAppReady || didInitialAreaChange) {
      if (isNative()) {
        initNative();
      } else {
        initWeb();
      }
    }
  }, [initNative, initWeb, isAppReady, didInitialAreaChange]);

  useEffect(() => {
    if (isNative()) {
      handleAppUpgrade();
    }
  }, [handleAppUpgrade]);

  useEffect(() => {
    let appListener: PluginListenerHandle;

    if (isNative() && Capacitor.isPluginAvailable('App')) {
      (async () => {
        const { App } = await import('@capacitor/app');
        appListener = await App.addListener('appStateChange', async ({ isActive }) => {
          if (isActive) {
            initNativeNotification();
            dispatch(getDartAlertInfo());
          }
        });
      })();
    }

    return () => {
      appListener?.remove();
    };
  }, [dispatch]);

  useEffect(() => {
    const data = {
      guest_id: guestId,
      organization_code: appOrgCode,
      platform: Capacitor.getPlatform(),
      version: environment.versionNumber,
      role: currentUser?.role ? 'Advocate' : 'Guest',
      advocate_id: currentUser?.user_id,
    };

    if (data.guest_id) {
      if (currentUser?.role && isTS) {
        posthog?.identify(guestId, {
          ...data,
          service_provider_organization_id: currentUser?.service_provider_organization_id,
        });
      } else {
        posthog?.identify(guestId, data);

        const eventName = isBurns ? 'org_code_changed_to_burns' : 'reset_role_to_guest';
        posthog?.capture(eventName, {
          $unset: ['service_provider_organization_id'],
        });
      }
    } else {
      posthog?.reset();
    }
  }, [
    appOrgCode,
    currentUser?.role,
    currentUser?.service_provider_organization_id,
    currentUser?.user_id,
    guestId,
    posthog,
  ]);
};
