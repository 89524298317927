import { isPlatform } from '@ionic/core';
import type { AnimationBuilder } from '@ionic/react';
import { createAnimation } from '@ionic/react';

import { environment } from '../environment/environment';

export const isNative = () => (isPlatform('ios') || isPlatform('android')) && !isPlatform('mobileweb');

export const setDidOnboarding = () => {
  localStorage.setItem('didOnboarding', 'true');
};

export const getDidOnboarding = () => {
  return localStorage.getItem('didOnboarding') === 'true';
};

export enum Org {
  Saskatchewan = 'sk',
  BurnsWay = 'burns',
  TestSaskatchewan = 'sk2',
}

export enum AppCode {
  TalkingStick = 'ts',
  TheBurnsWay = 'tbw',
}

export const getOrgCode = (): Org => environment.orgCode as Org;

export const getAppCode = (): AppCode => environment.appCode as AppCode;
export const isBurns = getAppCode() === AppCode.TheBurnsWay;
export const isTS = getAppCode() === AppCode.TalkingStick;

export const appName = isBurns ? 'the Burns Way' : 'Talking Stick';
export const infoEmail = isBurns ? 'info@theburnsway.ca' : 'info@talkingsrick.app';

export const setOrgBodyClass = () => {
  const orgCode = getOrgCode();
  if (orgCode !== Org.Saskatchewan) {
    document.body.classList.toggle(`${orgCode}-org`, true);
  }
};

export const getOrgImg = () => {
  switch (getOrgCode()) {
    case Org.BurnsWay:
      return {
        appLogo: {
          src: 'assets/imgs/tbw-loading-logo.svg',
          alt: 'the Burns Way logo',
        },
      };
    case Org.TestSaskatchewan: // For testing purposes
      return {
        appLogo: {
          src: 'assets/imgs/TS-LOGO.svg',
          alt: 'Talking Stick logo',
        },
        loadingImage: {
          src: 'assets/imgs/BISON.png',
          alt: 'Outline of a buffalo containing mountains, water, and sun moving through sky.',
        },
        heroImage: 'linear-gradient(85deg, #6899c7 0%, #b12424 51.04%, #e7d725 100%)',
        languageImage: 'no-repeat center center / cover url(/assets/imgs/language-selection-header.svg)',
      };
    default:
      return {
        appLogo: {
          src: 'assets/imgs/TS-LOGO.svg',
          alt: 'Talking Stick logo',
        },
        loadingImage: {
          src: 'assets/imgs/BISON.png',
          alt: 'Outline of a buffalo containing mountains, water, and sun moving through sky.',
        },
        heroImage: 'no-repeat center center / cover url(/assets/imgs/anonymous-chat-header.webp)',
        languageImage: 'no-repeat center center / cover url(/assets/imgs/language-selection-header.svg)',
      };
  }
};

export const getAnimationBuilder: AnimationBuilder = (baseEl, ops) => {
  const enteringAnimation = createAnimation()
    .addElement(ops.enteringEl)
    .fill('forwards')
    .duration(150)
    .fromTo('opacity', '0', '1');
  const leavingAnimation = createAnimation().addElement(ops.leavingEl).fill('forwards').fromTo('opacity', '1', '0');
  const animation = createAnimation().addElement(baseEl).addAnimation([enteringAnimation, leavingAnimation]);
  return animation;
};

export const getEvolvingMessageStatus = () => localStorage.getItem('seenEvolvingMessage') === 'true';
export const setEvolvingMessageStatus = () => localStorage.setItem('seenEvolvingMessage', 'true');

export const getViewInternalFACommunities = () => localStorage.getItem('view_internal_fa_communities') === 'true';
export const setViewInternalFACommunities = () => localStorage.setItem('view_internal_fa_communities', 'true');
export const removeViewInternalFACommunities = () => localStorage.removeItem('view_internal_fa_communities');
