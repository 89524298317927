import { useEffect } from 'react';

import { removeGuestThreadId } from '../api/guest';
import { environment } from '../environment/environment';
import { isNative } from '../helpers/device.helper';
import { getStoredAuthAsync, removeStoredAuthAsync } from '../services/login';
import { clearAdvocateState, store } from '../store';

export const useBeforeUnload = (thread_id: string | undefined | null) => {
  useEffect(() => {
    // We prevent the user to leave the chat showing the generic browser-specified dialog
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };
    // If they leave ("unload") the page, we end the chat as a "left chat" and clean state, including advocate logout
    // Note 1: axios doesn't work here, so we need to use fetch
    // Note 2: This doesn't work for Firefox
    const handleUnload = async (_event: Event) => {
      const token = await getStoredAuthAsync();
      const url = `${environment.baseEndpoint}${token ? 'advocate' : 'guest'}/v0_end_left_chat`;
      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        appversion: `${environment.versionNumber}.${environment.buildNumber}`,
      };
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      const device_id = localStorage.getItem('device_id');
      const device_os = localStorage.getItem('device_os');
      const data = { device_id, device_os, thread_id };
      const options: RequestInit = {
        method: 'POST',
        headers,
        keepalive: true,
        body: JSON.stringify(data),
      };

      if (token) {
        await removeStoredAuthAsync();
      } else {
        removeGuestThreadId();
      }
      store.dispatch(clearAdvocateState());

      return fetch(url, options);
    };

    if (!isNative() && thread_id) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('unload', handleUnload);
    }

    return () => {
      if (!isNative()) {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('unload', handleUnload);
      }
    };
  }, [thread_id]);
};
