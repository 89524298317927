import { settingsOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { IonButton, IonButtons, IonCol, IonGrid, IonHeader, IonIcon, IonRow, IonTitle, IonToolbar } from '@ionic/react';

import { TSButton } from '../../components/atomic';
import { getCustomIcon } from '../../helpers/utils.helper';
import { useAppDispatch, useAppSelector } from '../../store';
import { GradientHeader } from '../../theme/GradientHeader';
import { ACCOUNT_COMPONENT, setCurrentComponent } from './AdvocateAccountSlice';
import { ADVOCATE_COMPONENT } from './AdvocateChatSlice';

type AdvocatePageTitle = 'account' | 'resources' | 'chat';

const profileHeaderConfigs = [
  {
    component: ACCOUNT_COMPONENT.PROFILE,
    prevComponent: undefined,
    title: 'generic.profile',
  },
  {
    component: ACCOUNT_COMPONENT.EDIT_PROFILE,
    prevComponent: ACCOUNT_COMPONENT.PROFILE,
    title: 'personalDetailsPage.title',
  },
  {
    component: ACCOUNT_COMPONENT.LIVED_EXPERIENCES,
    prevComponent: ACCOUNT_COMPONENT.EDIT_PROFILE,
    title: 'advocateChatCard.livedExperiences',
  },
  {
    component: ACCOUNT_COMPONENT.CHANGE_PASSWORD,
    prevComponent: ACCOUNT_COMPONENT.PROFILE,
    title: 'advocateChangePasswordPage.title',
  },
] as const;

const SettingsIconHeader = ({ advocateTab }: { advocateTab: AdvocatePageTitle }) => {
  const history = useHistory();

  return (
    <TSButton
      onClick={() => {
        history.push({ pathname: '/settings', state: { advocateTab } });
      }}
      className="advocate-page-settings-button"
      variant="grey8"
    >
      <IonIcon className="title-large" slot="icon-only" icon={settingsOutline} />
    </TSButton>
  );
};

export const AdvocatePageHeader = ({ advocatePageTitle }: { advocatePageTitle: AdvocatePageTitle }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentProfileComponent = useAppSelector((state) => state.advocateAccountSlice.currentComponent);
  const currentProfileHeaderConfig = profileHeaderConfigs.find(
    (config) => config.component === currentProfileComponent,
  );
  const advocateChatPageStatus = useAppSelector((state) => state.advocateChatSlice.advocateChatPageStatus);
  const hasGradientHeader = ![ADVOCATE_COMPONENT.CHAT].includes(advocateChatPageStatus);

  const showHeader = () => {
    switch (advocatePageTitle) {
      case 'chat':
        return (
          hasGradientHeader && (
            <>
              <GradientHeader />
              <IonRow className="ion-justify-content-center">
                <IonCol size="12" sizeSm="4">
                  <IonToolbar>
                    <IonRow className="ion-justify-content-end ion-align-items-center ion-margin-horizontal">
                      <SettingsIconHeader advocateTab={advocatePageTitle} />
                    </IonRow>
                  </IonToolbar>
                </IonCol>
              </IonRow>
            </>
          )
        );
      case 'account':
        return (
          currentProfileHeaderConfig && (
            <IonHeader className="ion-no-border ion-padding-horizontal">
              <IonGrid className="ion-no-padding">
                <IonRow className="ion-justify-content-center">
                  <IonCol size="12" sizeSm="4">
                    <IonToolbar className="pageToolbar">
                      {currentProfileHeaderConfig.prevComponent && (
                        <IonButtons className="ion-no-margin" slot="start">
                          <IonButton
                            onClick={() => {
                              if (currentProfileHeaderConfig.prevComponent) {
                                dispatch(setCurrentComponent(currentProfileHeaderConfig.prevComponent));
                              }
                            }}
                          >
                            <IonIcon src={getCustomIcon('chevron-left')} />
                          </IonButton>
                        </IonButtons>
                      )}
                      <IonTitle className="ion-text-center title-medium text-bold">
                        {t(`${currentProfileHeaderConfig.title}`)}
                      </IonTitle>
                      <IonButtons className="ion-no-margin" slot="end">
                        <SettingsIconHeader advocateTab={advocatePageTitle} />
                      </IonButtons>
                    </IonToolbar>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonHeader>
          )
        );
      case 'resources':
        return (
          <IonHeader className="ion-no-border ion-padding-horizonal">
            <IonGrid className="ion-no-padding">
              <IonRow className="ion-justify-content-center">
                <IonCol size="12" sizeSm="5">
                  <IonToolbar className="pageToolbar">
                    <IonTitle className="ion-text-center title-medium text-bold">{t('generic.resources')}</IonTitle>
                    <IonButtons className="ion-no-margin" slot="end">
                      <SettingsIconHeader advocateTab={advocatePageTitle} />
                    </IonButtons>
                  </IonToolbar>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonHeader>
        );
      default:
        return;
    }
  };

  return showHeader();
};
