export const environment = {
  baseEndpoint: 'https://api-qa.talkingstick.app/',
  buildNumber: "28961013",  
  versionNumber: "3.1.0",  
  environment_deploy: 'qa',
  rayGunKey: 'hqaZDpkENyp0WOVqpDhdg',
  authResultKey: '9cb6f759-883b-437f-b07f-34570968787b',
  cryptoKey: 'b4c91138-f3a1-4e8a-aba6-cacb35f21924',
  posthogToken: 'phc_FxfNWKWqYxa9nm24KsWccHzMR776akapxZWqhHjFTGJ',
  orgCode: 'sk',
  appCode: 'ts',
  locizeProjectId: '714c2f9a-6c84-4052-b7f0-1e6469e617d7',
  appStoreUrls: {
    sk: {
      ios: 'itms-beta://beta.itunes.apple.com/v1/app/1610807610',
      androidPlay: 'https://play.google.com/store/apps/details?id=app.talkingstick',
      androidMarket: 'market://details?id=app.talkingstick',
    },
    burns: {
      ios: 'itms-beta://beta.itunes.apple.com/v1/app/6670564451',
      androidPlay: 'https://play.google.com/store/apps/details?id=app.theburnsway',
      androidMarket: 'market://details?id=app.theburnsway',
    },
  },

  firebaseConfig: {
    apiKey: 'AIzaSyBPDMiYfNu_MK7krowyG-u9CKCv1F1t0a8',
    authDomain: 'talking-stick-qa-aaf2c.firebaseapp.com',
    projectId: 'talking-stick-qa-aaf2c',
    storageBucket: 'talking-stick-qa-aaf2c.appspot.com',
    messagingSenderId: '215305996060',
    appId: '1:215305996060:web:0e6fc9e2ef278164ff9cd9',
    vapidKey: 'BEgu-43tkAzs9FSpjT67JM-16TaE2bFmd15dqWB_eThcXK107sFvvKBpJoDD0XSPJTfkhpEAi-BhMkwhq_873mY',
  },
};
